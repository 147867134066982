import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import avater from "../../Assets/avater.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
           Who is <span className="purple"> Unais Ali? </span> Founder UTECH Digital Education
            </h1>
            <p className="home-about-body">
        
Unais Ali is a visionary entrepreneur and founder of UTech Digital Education, a groundbreaking startup in Pakistan dedicated to teaching the latest technologies to children. Unais graduated as an electronic engineer in 2021 and immediately embarked on a mission to revolutionize education for young minds in his country. With a passion for technology and education, Unais has trained over 20,000 students through UTech Digital Education, creating a significant impact in the field of digital education.
                <br /> I fell in love with programming and I have at least learnt
              something, I think… 🤷‍♂️
           
              <br />I am fluent in classics like
              <i>
                <b className="purple"> C++, Javascript and Python. </b>
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Web Technologies (With React Js) and Products </b> and
                also in areas related to{" "}
                <b className="purple">
                  Deep Learning , Natural Launguage Processing ,Artificial Intelligence & Machine Learning .
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src="https://i.ibb.co/m0tvr4Z/Whats-App-Image-2022-05-09-at-12-24-18-PM-modified.png" className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/unaisshazan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/unaisali96"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/unais-ali-a88245176/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/unais__ali"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
