import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import axios from "axios";

import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
  const uri = "";
  const [spojRank, upadteSpojRank] = useState(0);
  const [hackerrank, upadteHackerank] = useState(0);
  const [sem, upadateSem] = useState(0);
  const [cgpa, upadteCgpa] = useState(0);

  useEffect(() => {
    axios
      .get(uri)
      .then((res) => {
        upadteSpojRank(res.data.message[0].spojRank);
        upadteHackerank(res.data.message[1].hackerrank);
        upadteCgpa(res.data.message[2].cgpa);
        upadateSem(res.data.message[3].sem);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href="https://drive.google.com/file/d/1HIF0nw80-9mDzzCVVrjIxIb9YHP4dQip/view?usp=sharing" target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
          <h3 className="resume-title">Startups & Companies</h3>
            <Resumecontent
              title="CEO At UTech  Digital Education [Coding School For kids]"
              content={[
                "Who is Unais Ali? He is a founder of UTech a coding school.UTech Digital Education was established with the purpose of addressing the poor education system in many countries which has resulted in a lack of quality engineers and limited job creation opportunities. Our startup is committed to providing technology education to young students aged between 7-15 years old",
              ]}
            />
                           <Resumecontent
              title="Incubated At National Incubation Center Karachi"
              content={[
                "Cohorts 8 and 9, is thrilled to announce a major milestone in our journey! 🚀 With invaluable support & mentorship from NIC Karachi, we are now equipped to reshape digital learning in Pakistan.",
              ]}
            />
                      <Resumecontent
              title="Training Partner Training Partner Mohammad Ali Jinnah University"
              content={[
                "UTech has teamed up with Muhammad Ali Jinnah University to offer courses for children aged 7 to 15, with the aim of preparing them for a career in engineering.",
              ]}
            />
                     <Resumecontent
              title="Training Partner Training Partner NCL NED University"
              content={[
                "UTech has teamed up with NCL-NED University to offer courses for children aged 7 to 15, with the aim of preparing them for a career in engineering.",
              ]}
            />
                  
            <Resumecontent
              title="Curriculum Developer At Asad Academy   [Online Home Schooling Academy]"
              content={[
                "Asad Academy is an online schooling platform that redefines learning to promote and enhance excellence by empowering students and educators, to thrive in a globally networked society, on a completely customized approach. No matter what you’re looking for, Asad Academy is ready to customize a program that works best for your child.",
              ]}
            />
                
          
            <h3 className="resume-title">Work Experience</h3>
                    <Resumecontent
              title="Project Manager Project Manager Asra Soft · Contract"
              date="Sep 2022 - Sep 2023"
              content={[
                "As project manager, I led and managed a team of 10 software engineers to successfully complete a web application project on schedule.",
                
              ]}
            />
            <Resumecontent
              title="Lecturer [Ziauddin University]"
              date="Dec 2021 - July 2022"
              content={[
                "BEE (Basic Electrical Engineering) Lecturer at Ziauddin University",
                
              ]}
            />
          
            <Resumecontent
              title="Frontend Developer Intern [Lambda Theeta Software House]"
              date="Dec 2019 - September 2020"
              content={[
                "Worked on the development of a Routing Software website",
                
              ]}
            />
                     <Resumecontent
              title="Project Developer Intern [Instrumentation Center, Ned University]"
              date="Dec 2019 - September 2020"
              content={[
                "Worked as a 3D designer",
                "Worked as an Arduino Programmer",
                "Worked as a Machine Learning Projects Developer",
               
                
              ]}
            />
         
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="Electronics Engineering [NED University, Karachi]"
              date="2017 - 2021"
              content={[`(CGPA: 3.6)`]}
            />
                  <Resumecontent
              title="Intermediate [Govt Dehli Science College, Karachi]"
              date="2016 - 2017"
              content={[`(Grade: A1)`]}
            />
     <Resumecontent
              title="Matric From : The Foundataion Grammer School"
              date="2016"
              content={[`(Grade: A)`]}
            />
                    <h3 className="resume-title">Achivements of Unais Ali With UTech</h3>
            <Resumecontent
              title="Resource Person"
              content={[
                "It was a capacity-building program for underprivileged areas where children had never used computers before.NED University selected me and four members of my team, where we not only taught them how to use skills but also empowered them to sell their handcrafted items online. A complete report is available on request and will be provided.",
              ]}
            />
                  
               
                  <Resumecontent
              title="Guest Speaker Idea Tank Bootcamp"
              content={[
                "I was a guest speaker at the IDEA Tank Bootcamp, organized by NED Entrepreneurship Society (NES) and the Business Incubation Centre (BIC) at NED University. The bootcamp aimed to elevate the professional skills of young entrepreneurs, teaching them how to pitch, fund, and manage their startups. Attendees learned from industry experts on enhancing business management skills.",
              ]}
            /> 
              
              
                 
                         <Resumecontent
              title="Shell Tameer Oustanding Pitch"
              content={[
                "Unais Ali, the founder and CEO of UTech Digital Education, had his idea listed among the top 5 at Shell Tameer's first Virtual Bootcamp, launched in collaboration with NED University. For two consecutive years, Tameer has been training young engineers in enterprise development to help convert their final year ideas into sustainable business ventures. This year, for the first time, the university opened applications to students aspiring to take entrepreneurship as a career. Thirty-four aspiring entrepreneurs were trained, resulting in six business ideas focused on energy innovation, education, automobile services, and artificial intelligence (IoT). A seasoned panel of judges from Shell, industry, and academia selected the top five entrepreneurial ideas.",
              ]}
               
            />
                   
             
              <Button variant="primary" href="https://tameer.shell.com.pk/tameer-news/fueling-the-green-future-programme-activities.html" target="_blank">
            <AiOutlineDownload />
            &nbsp;Refrence of Shell Tameer
          </Button> 
              
                <Button variant="primary" href="https://oric.neduet.edu.pk/idea-tank-bootcamp" target="_blank">
            <AiOutlineDownload />
            &nbsp;Refrence of ORIC
          </Button>
                
                
                <Button variant="primary" href="https://oric.neduet.edu.pk/sites/default/files/Capacity%20builiding%20and%20Mentoring.pdf" target="_blank">
            <AiOutlineDownload />
            &nbsp;Refrence of Capacity Building
          </Button>
               
          
                
                
                   
            <h3 className="resume-title">Final Year Project</h3>
            <Resumecontent
              title="SENTIMENTS ANALYSIS"
              content={[
                "Sentiment Analysis Using Automated Facial Recognition with Jetson nano and web app using React js",
              ]}
              
                
            />
                
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href="https://drive.google.com/file/d/1HIF0nw80-9mDzzCVVrjIxIb9YHP4dQip/view?usp=sharing" target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
